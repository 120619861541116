import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import { StaticPageTemplate } from './static-page-tpl';

const PrivacyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  
  return (
    <Layout showContact={false} showNewsletter={false} showFooter={false} meta={frontmatter.meta}>
      <StaticPageTemplate {...frontmatter} />
    </Layout>
  );
};

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PrivacyPage;

export const privacyPageQuery = graphql`
  query PrivacyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-page" } }) {
      html
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }

        title
        content
      }
    }
  }
`;
